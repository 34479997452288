// 
// 
// Urls
// Config file for urls used within gatsby 
// 
// 


// process.env.MERMAID_HOST
//-- mermaid url
export const mermaidUrl = process.env.MERMAID_HOST;

//-- mermaid Image Url
export const mermaidImgUrl = `${mermaidUrl}/-/img`;

//-- mermaid flowmoji background
export const mermaidFlowmojiBgUrl = `${mermaidUrl}/-/img/bg-tile-generic.png`;

// google address api
export const googleAddressApiUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAP_KEY}&libraries=places`;


//-- youtube image url
export const getYoutubeImageUrl = (imgSrc) =>{
    return `https://img.youtube.com/vi/${imgSrc}/hqdefault.jpg`;
}
export const getYoutubeVideoUrl = (videoSrc) =>{
    return `https://www.youtube.com/embed/${videoSrc}?autoplay=1&mute=0`;
}


//-- vimeo image url
export const getVimeoVideoDetail= (videoId) =>{
    return `https://vimeo.com/api/v2/video/${videoId}.json`;
}
export const getVimeoVideoUrl = (videoSrc) =>{
    return `https://player.vimeo.com/video/${videoSrc}?autoplay=1`;
}