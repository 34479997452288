import React from 'react';
import { mermaidImgUrl } from 'constants/urls';


function Image (props) {

    let imageSrcRaw = props.src !== undefined ? props.src : '';
    let imageSrc = `${mermaidImgUrl}${imageSrcRaw}`;

    if(props.imagesrcfrom!==undefined && props.imagesrcfrom!==null && props.imagesrcfrom==='youtube'){
        imageSrc = props.imagesrc;
    }
    
    return (
        <img {...props} src={imageSrc} alt={props.alt} />
    );
}

export default Image;