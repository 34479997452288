import React from 'react';
import Link from 'components/common/Link';
import Image from 'components/common/Image';
import { LinkModal } from 'kh-common-components';

function Panel (props) {

    let panelId = props.id !== undefined ? props.id : 'default';

    let relatedPanel = props.data.content.filter(x => x.id === panelId);

    return (
        <>
            {relatedPanel !== undefined && relatedPanel &&
                relatedPanel.map((data, index) => {

                    {/* title */}
                    let defaultTitleClass = 'mer-panel__title';
                    let titleClass = data.titleClass !== undefined ? `${defaultTitleClass} ${data.titleClass}` : `${defaultTitleClass}`;

                    {/* cta */}
                    const defaultCtaClass = 'mer-panel__actions mer-button-group';
                    let ctaCol = data.ctaCol !== undefined ? ` ${data.ctaCol}` : '';
                    let ctaClass = `${defaultCtaClass}${ctaCol}`;

                    {/* panel class */}
                    const defaultPanelClass = 'row mer-panel';
                    let panelCol = data.panelCol !== undefined ? ` ${data.panelCol}` : '';
                    let panelClass = defaultPanelClass + panelCol;

                    {/* content class */}
                    const defaultContentClass = 'content';
                    let contentCol = data.contentCol !== undefined ? ` ${data.contentCol}` : '';
                    let contentClass = defaultContentClass + contentCol;

                    {/* img class */}
                    const defaultImgClass = 'mer-panel__images';
                    let imgCol = data.imgCol !== undefined ? ` ${data.imgCol}` : '';
                    let imgClass = `${defaultImgClass}${imgCol}`;

                    {/* video class */}
                    const defaultVidClass = 'mer-panel__videos';
                    let vidCol = data.vidCol !== undefined ? ` ${data.vidCol}` : '';
                    let vidClass = `${defaultVidClass}${vidCol}`;

                    {/* panel description class */}
                    let descriptionClass = data.disableDescriptionClass !== undefined && data.disableDescriptionClass === true ? '' : 'mer-panel__description';

                    return (
                        <div className={panelClass} key={index}>
                            <div className={contentClass}>

                                {data.title &&

                                    <h2 className={titleClass} dangerouslySetInnerHTML={ {__html: data.title} }></h2>
                                }

                                {data.description && data.description.length > 0 &&
                                    data.description.map((desc, descIndex) => {

                                        return (
                                            <React.Fragment key={descIndex}>
                                               {desc.title && 
                                                    <h5 className={`mt-3 ${desc.titleClass}`} key={`title-${descIndex}`}>{desc.title}</h5>
                                                }
                                                {desc.text &&
                                                    <p className={desc.descriptionClass} key={`desc-${descIndex}`}>{desc.text}</p>
                                                }
                                                {desc.textHtml && 
                                                    <p className={desc.descriptionClass} key={`desc-${descIndex}`} dangerouslySetInnerHTML={ {__html: desc.textHtml} }></p>
                                                }
                                            </React.Fragment>
                                        );
                                    })
                                }

                                {props.modalTitle && 
                                    <LinkModal {...props}
                                                modalContents={props.modalContents}
                                                useModal="true"
                                                displayContent={props.modalDisplayContent}
                                                title={props.modalTitle}
                                                modalTitle={props.modalTitle}
                                                modalContentName={props.modalContentName}
                                                okayButtonLabel={props.modalOkaybuttonLabel}
                                    /> 
                                }

                                {data.cta && data.cta.length > 0 &&
                                    <div className={ctaClass}>
                                        {data.cta.map((cta, ctaIndex) => {
                                            return (<Link text={cta.text}
                                                type={cta.type}
                                                link={cta.link}
                                                linkClass={cta.linkClass}
                                                key={ctaIndex} />);
                                        })}
                                    </div>
                                }

                                {data.ctaDescriptor &&
                                    <p>
                                        <small>{data.ctaDescriptor}</small>
                                    </p>
                                }

                            </div>

                            {data.img &&
                                <div className={imgClass}>
                                    <Image className="mer-panel__image" src={data.img} alt={data.imgAlt} />
                                </div>
                            }

                            {data.svg &&
                                <div className={imgClass}>
                                    <Image className="mer-panel__image"
                                           src={`/icons/flowmoji/${data.svg}.svg`}
                                           alt={data.svgAlt}
                                />
                                </div>
                            }

                            {data.video &&
                                <div className={vidClass}>
                                    <div className="mer-panel__video">
									    <iframe src={`https://player.vimeo.com/video/${data.video}?title=0&amp;byline=0&amp;portrait=0&amp;badge=0`} frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" data-vimeo-tracked="true" data-ready="true"></iframe>
                                    </div>
                                </div>
                            }

                            {props.imgLocal &&
                                <div className={imgClass}>
                                    <img src={props.imgLocal} 
                                         className="mer-panel__image"
                                         alt={data.imgAlt} />
                                </div>
                            }
                        </div>
                    );
                })
            }
        </>
    );
}

export default Panel;