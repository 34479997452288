import React from 'react';

function LayoutPanel(props) {

  let defaultClass = 'module-wrapper';
  
  let backgroundClass = props.background !== undefined ? ` ${props.background}` : '';
  let borderClass = props.border !== undefined ? ` ${props.border}` : '';
  let paddingClass = props.padding !== undefined ? ` ${props.padding}` : '';
  let marginClass = props.margin !== undefined ? ` ${props.margin}` : '';
  let themeClass = props.theme !== undefined ? ` ${props.theme}` : '';
  let colClass = props.col !== undefined ? ` ${props.col}` : '';

  let sectionClass = `${defaultClass}${colClass}${themeClass}${marginClass}${paddingClass}${backgroundClass}${borderClass}`;

    return (
        <section className={sectionClass} id={props.id} style={props.inlineStyle}>
            {props.children}
        </section>
    );
}

export default LayoutPanel;