import React from 'react';

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from "components/layout/layoutPanel";

import Image from 'components/common/Image';
import Link from 'components/common/Link';
import Panel from "components/common/Panel";

import panelData from 'constants/kwik-gas/panel';

// asset images
// https://www.gatsbyjs.com/docs/how-to/images-and-media/importing-assets-into-files/
import heroBanner from 'images/hero-kwikgas.png';

import 'styles/pages/kwik-gas.css';

function KwikGasPage() {
	return (
		<>
            <SEO title="Gas bottles | Kleenheat Kwik-Gas" 
            	 description="Find the nearest location to swap your empty gas bottle or buy a new one. Our gas bottles are perfect for your BBQ, camping, and outdoor gas appliance needs."
        	/>
            <Header />
            <LayoutMain>

            	{/* hero banner */}
            	<LayoutPanel background="mer-bg--primary-cyan"                 
			                 padding="mer-py-lg"
			                 theme="mer-theme--cyan"
			                 id="kwik-gas-hero-banner">
			        <div className="container">
			            <div className="row mer-panel justify-content-around align-items-center">
		                    <div className="col-10 col-sm-7 col-lg-6 mer-text--size-lg order-sm-2">

		                        <div className="content">
		                            <h2 className="mer-typography--headline1">Get your gas and go!</h2>
		                            <p>Kwik-Gas is Kleenheat's safe and convenient gas bottle exchange program. Our gas bottles are perfect for your BBQ, outdoor heating, and portable gas appliance needs.</p>

		                            <div className="mer-panel__actions mer-button-group">
		                                <Link text="Find a retailer"
		                                link="/locations"
		                                linkClass="mer-button mer-button--primary" />

		                                <Link text="Quick start guide"
		                                link="#quickstart"
		                                linkClass="mer-button mer-button--secondary" />
		                            </div>
		                        </div>
		                    </div>

		                    <div className="mer-panel__images col-8 col-sm-5 col-lg-4 col-xl-5 order-sm-1">
		                        <img src={heroBanner} 
		                           className="mer-panel__image kwik-gas-cylinder"
		                           alt="Kwik-gas cylinder" />
		                    </div>
			            </div>
					</div>
			    </LayoutPanel>

	        	{/* usp */}
	        	<LayoutPanel background="mer-bg--ui-light"
			                 padding="mer-pt-ju mer-pb-lg">

		                <div className="container">
		                	<div className="row align-items-center justify-content-center">
		                		<div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg">
					                <div className="content text-center">
					                	<h1 className="mer-color--primary-cyan">Find our gas bottles</h1>
					                	<p>We've partnered with local retailers to ensure our gas bottles are available to exchange or purchase at locations near you.</p>
					                	<h5>Find our gas bottles at these major retailers:</h5>
					                </div>
					            </div>

					            <div className="col-10 col-sm-12 col-lg-8">
					            	<div className="row align-items-center justify-content-center">

				                		{/* bp */}
				                		<div className="col-3 col-sm-2 mer-p-de">
				                			<Image className="mer-panel__image"
				                                 src="/icons/external/logo-bp.svg"
				                                 alt="BP Fuel"
												 title="BP Fuel"
				                          	/>
				                		</div>

				                		{/* puma */}
				                		<div className="col-3 col-sm-2 mer-p-de">
				                			<Image className="mer-panel__image"
				                                 src="/logo/logo-puma.png"
				                                 alt="Puma Fuel"
				                                 title="Puma Fuel"
				                          	/>
				                		</div>

				                		{/* iga */}
				                		<div className="col-3 col-sm-2 mer-p-de">
				                			<Image className="mer-panel__image"
				                                 src="/logo/logo-iga.png"
				                                 alt="IGA Supermarkets"
				                                 title="IGA Supermarkets"
				                          	/>
				                		</div>

				                		{/* united */}
				                		<div className="col-3 col-sm-2 mer-p-de">
				                			<Image className="mer-panel__image"
				                                 src="/logo/logo-united.png"
				                                 alt="United Fuel"
				                                 title="United Fuel"
				                          	/>
				                		</div>

				                		{/* vibe */}
				                		<div className="col-3 col-sm-2 mer-p-de">
				                			<Image className="mer-panel__image"
				                                 src="/icons/external/logo-vibe.svg"
				                                 alt="Vibe Fuel"
				                                 title="Vibe Fuel"
				                          	/>
				                		</div>
				                	</div>
					            </div>
		                	</div>
		                </div>
		        </LayoutPanel>

		    	{/* usp */}
		    	<LayoutPanel background="mer-bg--ui-light"
			                 padding="mer-pb-lg">
			        <div className="container">
			        	<Panel data={panelData} 
			                   id="panel-1"
						/>
						<Panel data={panelData} 
			                   id="panel-2"
						/>
						<Panel data={panelData} 
			                   id="panel-3"
						/>
			        </div>
		        </LayoutPanel>

		    	{/* started with kwik-gas */}
		    	<LayoutPanel background="mer-bg--gs-grey-lighter"
			                 padding="mer-py-lg"
			                 id="quickstart">
			        <div className="container">
			            <div className="row mer-panel">

			              	<div className="col-10 col-sm-5 col-lg-5 col-xl-4 content">
			                	<h2 className="mer-typography--headline3">Getting started with Kwik-Gas</h2>
			                	<p>Watch our step-by-step video on how to connect gas bottles to your appliances, and how to check the gas level to ensure you don't run out mid-BBQ.</p>
								<p><strong>Please note this video features a POL valve connection. See below for details on a valve connection that has replaced the POL valve in Australia.</strong></p>
			              	</div>

							<div className="col-12 col-sm-6 offset-sm-1 mer-panel__videos">
							    <div className="mer-panel__video">
							        <iframe src="https://www.youtube.com/embed/BJejU7Iz_CA" frameborder="0" allow="autoplay" allowfullscreen></iframe>
							    </div>
							</div>
			            </div>
			        </div>
			    </LayoutPanel>

				{/* new valve */}
		    	<LayoutPanel background="mer-bg--gs-grey-lighter"
			                 padding="mer-pb-lg mer-pt-de"
			                 id="new-valve">
			        <div className="container">
			            <div className="row mer-panel">

			              	<div className="col-10 col-sm-6 offset-sm-1 order-sm-2">
			                	<h2 className="mer-typography--headline4">A new and improved valve connection</h2>
			                	<p>In 2021 a new valve connection was introduced across Australia for gas bottles like Kwik-Gas bottles. This new valve connection is now used for BBQ gas bottles, caravan gas bottles, and gas bottles used for other portable gas appliances.  The new valve design improves gas appliance safety and makes it easier to use and connect gas bottles to your appliances.</p>
								<div className="mer-panel__actions mer-button-group">
									<a href="/help/products-services/connecting-your-gas-cylinder-to-your-gas-appliance" className="mer-button mer-button--primary" title="Learn more">Learn more</a>
								</div>
			              	</div>

							<div className="col-12 col-sm-5 col-lg-5 col-xl-4 mer-panel__videos order-sm-1">
							    <div className="mer-panel__video">
							        <iframe src="https://player.vimeo.com/video/605313048" frameborder="0" allow="autoplay" allowfullscreen></iframe>
							    </div>
							</div>
			            </div>
			        </div>
			    </LayoutPanel>

		    	{/* kwik-gas reseller */}
		    	<LayoutPanel background="mer-bg--primary-royal"
		    				 theme="mer-theme--dark"
			                 padding="mer-py-lg"
			                 id="reseller">
			        <div className="container">
			            <div className="row mer-panel">

							<div className="col-10 col-xl-5 col-md-6 col-sm-7 offset-sm-1 order-sm-2 content">
								<h2>Become a Kwik-Gas bottle reseller</h2>
								<p>Opportunities are available for businesses to become Kwik-Gas bottle resellers and introduce a new income stream leveraging Kleenheat's professional and reliable supply chain.</p>
								<div className="mer-panel__actions mer-button-group">
									<a href="/contact" className="mer-button mer-button--primary" title="Contact us">Contact us</a>
								</div>
							</div>

							<div className="col-6 col-lg-3 col-sm-4 mer-panel__images order-sm-1">
								<Image className="mer-panel__image"
	                                 src="/icons/flowmoji/flowmoji-shop-dark.svg"
	                                 alt="Flowmoji search"
	                          	/>
							</div>
			            </div>
			        </div>
			    </LayoutPanel>

		    </LayoutMain>
			<Footer />
		</>
	)
}

export default KwikGasPage;